import React, { useEffect, useState, useContext } from "react";
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  Outlet,
  useNavigate,
} from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { useCookies } from "react-cookie";
import useGenres from "./hooks/useGenres";
import "./App.css";
import "./styles/media-query.css";
import Login from "./pages/login/Login";
import Home from "./pages/home/Home";
import SingleEvent from "./pages/single-event/SingleEvent";
import ThanksPage from "./pages/thanks/thanksPage";
import Footer from "./layout/footer/Footer";
import ScrollToTop from "./helpers/ScrollTop";
import { API_URL } from "./utils/config";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import translations from "./translations.json";
import AuthorizedLayout from "./layout/AuthorizedLayout";
import Logo from "./components/Logo";
import Lifestyle from "./pages/lifestyle/Lifestyle";
import DealDetail from "./components/DealDetail";
import ThanksDealPage from "./pages/ThanksDealPage";

// import PlaceholderDealImage from "./assets/placeholder-deal-image.png";

// const deals = [
//   {
//     id: 1,
//     image_url: PlaceholderDealImage,
//     title: "TRAINMORE",
//     location: "Amsterdam, Rotterdam",
//     teaser:
//       "10% korting op studenten abonnement en sport de eerste 6 maanden gratis.",
//     voordeel1_title: "",
//     voordeel1_description: "",
//     voordeel2_title: "",
//     voordeel2_description: "",
//     voordeel3_title: "",
//     voordeel3_description: "",
//     ticket_link: "",
//     category: "sport",
//     isDealOfTheWeek: true,
//     original_price: "14.99",
//     our_price: "7.50",
//     sold_out: false,
//   },
// ];

function App() {
  const navigate = useNavigate();
  const [cookie, setCookie] = useCookies(["token"]);
  const { genres, loading } = useGenres();
  const [eventsLoading, setEventsLoading] = useState(true);

  const { pathname } = useLocation();
  // const [isValidToken, setIsValidToken] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isValidToken, setIsValidToken] = useState(false);
  const [eventData, setEventData] = useState([]);
  // extract token from url
  const location = useLocation();
  const getTokenFromUrl = () => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get("token"); // Assuming the URL param is named 'token'
  };

  const getEventFromUrl = () => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get("eventId"); // Assuming the URL param is named 'token'
  };

  const tokenUrl = getTokenFromUrl();
  const token = tokenUrl || cookie.token;

  const [language, setLanguage] = useState(
    localStorage.getItem("language") || navigator.language.split("-")[0]
  );

  const [deals, setDeals] = useState([]);

  i18n.use(initReactI18next).init({
    resources: translations,
    lng: language,
    fallbackLng: "en",

    interpolation: {
      escapeValue: false,
    },
  });

  useEffect(() => {
    localStorage.setItem("language", language);
  }, [language]);

  const [handled, setHandled] = useState(false);

  const UserGuard = ({ token }) => {
    const location = useLocation();

    useEffect(() => {
      async function handleTokenValid(token) {
        if (handled) {
          return;
        }
        setHandled(true);

        const data = {
          token: token,
        };
        try {
          const result = await fetch(`${API_URL}read.php`, {
            mode: "cors",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          });
          const resData = await result?.json();

          const dealData = await (
            await fetch(`${API_URL}readDeals.php`, {
              mode: "cors",
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            })
          ).json();
          setDeals(dealData?.items);
          if (Array.isArray(resData?.items)) {
            setEventData(resData?.items);
            return true;
          } else {
            return false;
          }
        } catch (err) {
          console.error("Error===>", err);
          return false;
        }
      }

      if (isValidToken) {
        handleTokenValid(token).then((res) => {
          setIsLoading(false);
          setTimeout(() => {
            setEventsLoading(false);
          }, 500);
        });
        return;
      }
      handleTokenValid(token).then((isValid) => {
        // in case if we get token from url and it is valid we save the token to the cookie
        if (isValid) {
          setCookie("token", token, {
            path: "/",
            expires: new Date(Date.now() + 10 * 365 * 24 * 60 * 60 * 1000), // (10 * 60 * 60 * 1000) 10 minutes in milliseconds
          });
          navigate(`/${window.location.href.split("/").at(-1)}`);
        }
        if (isValid !== undefined) {
          console.log("NOT UNDEFINED");
          setIsValidToken(isValid);
          setIsLoading(false); // Mark loading as complete
          setTimeout(() => {
            setEventsLoading(false);
          }, 500);
        }
      });
    }, [token]);

    if (isLoading) {
      return (
        <div className="h-screen w-screen flex items-center justify-center hero-section">
          <Logo />
        </div>
      );
    }

    return isValidToken ? (
      <Outlet />
    ) : (
      <Navigate to="/login" state={{ from: location }} replace />
    );
  };

  return (
    <ChakraProvider theme={"dark"}>
      {/* <select
        className="language-selector"
        value={language}
        onChange={(e) => {
          setLanguage(e.target.value);
        }}
      >
        <option value="nl">🇳🇱 NL</option>
        <option value="en">🇺🇸 EN</option>
      </select> */}
      <ScrollToTop />
      <Routes>
        {(!token || !isValidToken) && (
          <Route path="/" element={<Navigate to="/login" replace />} />
        )}
        <Route
          path="login"
          element={
            <Login
              setIsValidToken={setIsValidToken}
              isValidToken={isValidToken}
            />
          }
        />
        <Route path="/" element={<UserGuard token={token} />}>
          <Route
            index
            element={
              <AuthorizedLayout setLanguage={setLanguage} language={language}>
                <Home
                  token={isValidToken ? token : null}
                  eventData={eventData}
                  genres={genres}
                  eventsLoading={eventsLoading}
                  setEventsLoading={setEventsLoading}
                  language={language}
                  setLanguage={setLanguage}
                />
              </AuthorizedLayout>
            }
          />
          <Route
            path="single-event"
            element={
              <AuthorizedLayout setLanguage={setLanguage} language={language}>
                <SingleEvent
                  token={token}
                  eventData={eventData}
                  language={language}
                  setLanguage={setLanguage}
                />
              </AuthorizedLayout>
            }
          />
          <Route
            path="deal"
            element={
              <AuthorizedLayout setLanguage={setLanguage} language={language}>
                <DealDetail
                  dealData={deals}
                  language={language}
                  setLanguage={setLanguage}
                />
              </AuthorizedLayout>
            }
          />
          <Route
            path="lifestyle"
            element={
              <AuthorizedLayout setLanguage={setLanguage} language={language}>
                <Lifestyle
                  deals={deals}
                  setLanguage={setLanguage}
                  language={language}
                />
              </AuthorizedLayout>
            }
          />
          <Route path="thanks" element={<ThanksPage />} />
          <Route path="thanksDeal" element={<ThanksDealPage />} />
        </Route>
      </Routes>
      {/* {!token || !isValidToken || pathname === "/login" || pathname.startsWith("/single-event")  ? null : (
        <Footer token={token} />
      )} */}
    </ChakraProvider>
  );
}

export default App;
