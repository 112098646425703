import React from "react";
import { Link } from "react-router-dom";
import DjSet from "../assets/dj-set.svg";
import ShoppingBag from "../assets/shopping-bag.svg";
import FeedbackIcon from "../assets/feedback-icon.svg";

export default function MobileMenu({ path }) {
  return (
    <div className="bottom-navbar text-white fixed bottom-0 w-full gap-[24px] py-[16px] flex items-center justify-center z-[1000]">
      <Link
        to="/"
        className={`text-[12px] hover:text-[#C9A6F5] leading-[14px] font-semibold flex flex-col gap-1 items-center justify-center ${
          path === "" && "text-[#C9A6F5]"
        }`}
      >
        <img
          src={DjSet}
          alt="Dj Set"
          className={`${path === "" && "fill-[#C9A6F5"}`}
        />
        Events
      </Link>
      <Link
        to="/lifestyle"
        className={`text-[12px] hover:text-[#C9A6F5] leading-[14px] font-semibold flex flex-col gap-1 items-center justify-center ${
          path === "lifestyle" && "text-[#C9A6F5]"
        }`}
      >
        <img
          src={ShoppingBag}
          alt="Dj Set"
          className={`${path === "" && "fill-[#C9A6F5"}`}
        />
        Lifestyle
      </Link>
      <Link
        to="https://yyo3i6vjjkc.typeform.com/to/LDhqX8fB"
        target="_blank"
        className="text-[12px] leading-[14px] font-semibold flex flex-col gap-1 items-center justify-center hover:text-[#C9A6F5] text-white"
      >
        <img src={FeedbackIcon} alt="Dj Set" />
        Feedback
      </Link>
    </div>
  );
}
