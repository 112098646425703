import { useEffect, useState, useRef } from "react";
// import axios from 'axios';
import { Checkbox, Input, effect } from "@chakra-ui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { LogoSvg } from "../../utils/svg";
import ArrowImg from "../../assets/image svg/arrow-left.svg";
import LoodsImg from "../../assets/image svg/Loods1.svg";
import LocateImg from "../../assets/image svg/Locatie.svg";
import CalendarImg from "../../assets/image svg/Calendar-N.svg";
import GenreIconImg from "../../assets/image svg/Music.svg";
import GuestListImg from "../../assets/image svg/Guestlist.svg";
import ShareImg from "../../assets/share-img.svg";
import UserImg from "../../assets/image svg/User.svg";
import MusicImg from "../../assets/image svg/Music.svg";
import GuestListSinImgs from "../../assets/image svg/Guestlist-sin1.svg";
import GuestListSinImg from "../../assets/image svg/Guestlist-sin.svg";
import CommunityTicketImg from "../../assets/community-ticket.svg";
import UserSinImg from "../../assets/image svg/User-sin.svg";
import WarningImg from "../../assets/image svg/Warning.svg";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import "dayjs/locale/nl";
import { NavLink } from "react-router-dom";
import Logo from "../../components/Logo";
import { useCookies } from "react-cookie";

// import timezone from 'dayjs/plugin/timezone';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import 'flatpickr/dist/themes/dark.css';
// import Flatpickr from 'react-flatpickr';
// import { Dutch } from 'flatpickr/dist/l10n/nl.js';

import "./SingleEvent.css";
import { signUpEventAPI } from "../../utils/config";
import { useTranslation } from "react-i18next";
import { CustomSelect } from "../home/Home";
import Icon from "../../components/Icon";

dayjs.extend(utc);

const SingleEvent = ({ token, setLanguage, language }) => {
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const textRef = useRef(null);
  const [eventData, setEventData] = useState([]);
  const [soldOutStatus, setSoldOutStatus] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [formValid, setFormValid] = useState(false);
  const [eFormData, setEFormData] = useState({
    Firstname: "",
    Lastname: "",
    Email: "",
    dob: "",
    gender: "",
    event: "",
    event_id: "",
    token: token,
  });
  const [eventLink, setEventLink] = useState("");
  const [dobPlaceHolder, setDobPlaceHolder] = useState(t("date_of_birth"));
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const refForm = useRef(null);
  const [isMobile, setIsMobile] = useState(false);

  const [cookie, setCookie] = useCookies(["token"]);

  useEffect(() => {
    setIsMobile(window.innerWidth < 768);
  }, [window.innerWidth]);

  useEffect(() => {
    if (location.state !== null) {
      const storage = JSON.parse(localStorage.getItem("allEvents"));
      const getPageData = storage.filter(
        (item) => item.EventId === location.state
      );

      setEventData(getPageData[0]);
      const soldOutText = getPageData[0].OutofStock["sold-out"];
      const ticketLink = getPageData[0].TicketLink || "";
      if (soldOutText === true || soldOutText === "true") {
        setSoldOutStatus(true);
      }
      if (ticketLink !== "") {
        if (ticketLink.includes("http://localhost/")) {
          const modifiedLink = ticketLink.replace("http://localhost/", "");
          setEventLink(modifiedLink);
        } else {
          setEventLink(ticketLink);
        }
      }
      setEFormData({
        ...eFormData,
        event: getPageData[0].EvenName,
        event_id: getPageData[0].EventId,
      });
    } else {
      navigate("/");
    }
  }, []);

  const handleCopyClick = () => {
    if (textRef.current) {
      const textToCopy = textRef.current.innerText;
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          // setCouponCode('Copied!')
          textRef.current.innerText = "Copied!";
          textRef.current.style.color = "green";
        })
        .catch((error) => {
          console.error("Error copying text:", error);
        });
    }
  };

  const handleBackClick = (e) => {
    e.preventDefault();
    navigate(`/`);
  };

  const onChange = (e) => {
    setEFormData({ ...eFormData, [e.target.name]: e.target.value.trim() });

    switch (e.target.name) {
      case "Firstname":
        if (formErrors.hasOwnProperty("Firstname") && formErrors.Firstname) {
          setFormErrors({
            ...formErrors,
            Firstname: !validateName(eFormData.Firstname),
          });
        }
        break;

      case "Lastname":
        if (formErrors.hasOwnProperty("Lastname") && formErrors.Lastname) {
          setFormErrors({
            ...formErrors,
            Lastname: !validateName(eFormData.Lastname),
          });
        }
        break;
      case "Email":
        if (formErrors.hasOwnProperty("Email") && formErrors.Email) {
          setFormErrors({
            ...formErrors,
            Email: !validateEmail(eFormData.Email),
          });
        }
        break;
      case "gender":
        if (formErrors.hasOwnProperty("gender") && formErrors.gender) {
          setFormErrors({
            ...formErrors,
            gender: ["Man", "Vrouw", "Onzijdig"].includes(eFormData.gender),
          });
        }
        break;
      case "agreement":
        setFormErrors({ ...formErrors, agreement: !e.target.value });
        break;
      case "dob":
        setFormErrors({ ...formErrors, dob: !validateDob(eFormData.dob) });

      default:
        break;
    }
  };

  const getThanks = () => {
    navigate(`/thanks?eventId=${eventData.EventId}`, {
      state: eventData.EventId,
    });
  };

  const focusDob = () => {
    setDobPlaceHolder("dd-mm-jjjj");
  };

  const blurDob = () => {
    setDobPlaceHolder(t("date_of_birth"));
  };

  const extractAge = (date) => {
    const [day, month, year] = date.split("-");
    const birthDate = new Date(`${year}-${month}-${day}`);
    const currentDate = new Date();

    let age = currentDate.getFullYear() - birthDate.getFullYear();

    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    // console.log('AGE', age)
    return age;
  };

  const changeDob = (e) => {
    const value = e.target.value;
    let formattedValue = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    let placeholder = "dd-mm-jjjj";
    let cursorPosition = formattedValue.length;
    const key = e.nativeEvent.inputType;

    const padWithZero = (number) => {
      return number.toString().padStart(2, "0");
    };

    // Format day (dd)
    if (formattedValue.length >= 2) {
      let day = parseInt(formattedValue.slice(0, 2), 10);

      if (day < 1) {
        day = 1;
      } else if (day > 31) {
        day = 31;
      }

      formattedValue = padWithZero(day) + "-" + formattedValue.slice(2);
      placeholder = formattedValue + placeholder.slice(3);
      cursorPosition = 3;
    }

    // Format month (mm)
    if (formattedValue.length >= 5) {
      let month = parseInt(formattedValue.slice(3, 5), 10);

      if (month < 1) {
        month = 1;
      } else if (month > 12) {
        month = 12;
      }

      formattedValue =
        formattedValue.slice(0, 3) +
        padWithZero(month) +
        "-" +
        formattedValue.slice(5);
      placeholder = placeholder.slice(0, 3) + "-mm-" + placeholder.slice(5);
      cursorPosition = 6;
    }

    // Format year (jjjj)
    if (formattedValue.length >= 10) {
      let year = parseInt(formattedValue.slice(6, 10), 10);

      const minYear = 1930;
      const maxYear = 2023;

      if (year < minYear) {
        year = minYear;
      } else if (year > maxYear) {
        year = maxYear;
      }

      formattedValue =
        formattedValue.slice(0, 6) + year.toString().padStart(4, "0");

      const age = extractAge(formattedValue);
      if (age < +eventData.MinimalAge) {
        setBtnDisabled(true);
      } else {
        setBtnDisabled(false);
      }
    }

    if (
      key === "deleteContentBackward" &&
      (formattedValue.length === 6 || formattedValue.length === 3)
    ) {
      formattedValue =
        formattedValue.slice(0, cursorPosition - 1) +
        formattedValue.slice(cursorPosition);
      cursorPosition -= 1;
    }

    setEFormData({ ...eFormData, dob: formattedValue });
    setDobPlaceHolder(placeholder);
  };

  async function sendEventData(dataObject) {
    // console.log('eventForm Data', eFormData);
    try {
      const res = await fetch(`${signUpEventAPI}`, {
        mode: "cors",
        method: "POST",
        body: JSON.stringify(dataObject),
      });
      const response = await res.json();
      console.log("create post request--->", response);
      // if (response.status) {
      //   window.alert(response.msg);
      // }
      return response;
    } catch (err) {
      console.error("Error===>", err);
    }
  }

  const validateName = (name) => {
    return /^[A-Za-z]+(\s[A-Za-z]+)*$/.test(name);
  };

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const validateDob = (dob) => {
    if (dob.length < 10) {
      return false;
    } else {
      return true;
    }
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!validateName(eFormData.Firstname)) {
      errors.Firstname = true;
      isValid = false;
    }

    if (!validateName(eFormData.Lastname)) {
      errors.Lastname = true;
      isValid = false;
    }

    if (!validateEmail(eFormData.Email)) {
      errors.Email = true;
      isValid = false;
    }

    if (eFormData.dob.length < 10) {
      errors.dob = true;
      isValid = false;
    }

    if (!["Man", "Vrouw", "Onzijdig"].includes(eFormData.gender)) {
      errors.gender = true;
      isValid = false;
    }

    if (!eFormData.agreement) {
      errors.agreement = true;
      isValid = false;
    }

    setFormErrors(errors);
    setFormValid(isValid);

    return isValid;
  };

  const handleSubmit = async (e, isScroll = false) => {
    setBtnDisabled(true);
    e.preventDefault();

    if (isScroll) {
      refForm.current?.scrollIntoView({ behavior: "smooth" });
    }

    if (validateForm()) {
      // console.log('eFormData:', eFormData);
      const { agreement, ...dataToSend } = eFormData;
      const response = await sendEventData(dataToSend);
      setBtnDisabled(false);
      // console.log('response status', response.status)
      if (response.status) {
        if (eventCategory === "Community ticket") {
          /*  window.open(eventLink, '_blank','noopener noreferrer');
           let link = document.createElement('a');
           link.href = eventLink
           link.target = '_blank';

          link.click() */ var newTab = window.open(eventLink, "_blank");
          if (newTab) {
            newTab.opener = null; // Set opener to null for security
            newTab.location.href = eventLink; // Set the location explicitly
          } else {
            // Fallback: Open link in the current window if the new tab couldn't be opened
            window.location.href = eventLink;
          }
        }
        getThanks();
      }
    } else {
      setBtnDisabled(false);
      console.error("Form validation Error");
    }
  };

  if (eventData.length < 1) {
    return (
      <div
        className="hero-section-single event-page"
        style={{ minHeight: "100dvh" }}
      >
        <div className="container-single">
          <div className="row">
            <div className="arrow">
              <a href="/" onClick={handleBackClick}>
                <img src={ArrowImg} alt="arrow" className="logo" />
              </a>
            </div>
            <div className="logo-Img">
              <NavLink to="/">
                <LogoSvg />
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const eventImage = eventData.EvenementImages;
  const eventTitle = eventData.EvenName.charAt(0) + eventData.EvenName.slice(1);

  const eventClub =
    eventData.TheClub.charAt(0).toUpperCase() +
    eventData.TheClub.slice(1).toLowerCase();
  const eventCity = eventData.CityName;
  const eventDate = dayjs
    .unix(eventData.EventDate)
    .utc()
    .locale("nl")
    .format("D MMM");
  const eventTime = dayjs
    .unix(eventData.EventDate)
    .utc()
    .locale("nl")
    .format("HH:mm");
  const eventCategory = eventData.EventCategorie;
  const eventAge = eventData.MinimalAge;
  const eventGenres = eventData.GenreList.split(", ")
    .map((genre) => {
      return genre === "Hitjes" ? t("hits") : genre;
    })
    .join(", ");
  const eventOriginalPrice = eventData.OriginalPrice;
  const eventOurPrice = eventData.OurPrice;
  const eventPriceLabel = `${t("instead_of")} ` || ""; // eventData.price_label
  const coupon = eventData.Coupon_code;
  // setCouponCode(coupon);

  const description =
    eventCategory !== "Community ticket"
      ? t("guestlist_paragraph")
      : t("community_ticket_paragraph");
  const btnText =
    eventCategory !== "Community ticket"
      ? t("put_me_on_the_list")
      : t("buy_community_ticket");

  const priceText =
    eventCategory !== "Community ticket"
      ? `${eventPriceLabel} €${eventOriginalPrice}`
      : `${eventPriceLabel} €${eventOriginalPrice}`;
  const eventPriceClass =
    soldOutStatus === true
      ? "soldout row justify-content-between"
      : "row justify-content-between";

  const shareContent = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: eventTitle,
          text: `Check out ${eventTitle} on 22night!`,
          url: `${window.location.href}&token=AMSLIJST`,
        });
        console.log("Content shared successfully!");
      } catch (error) {
        console.error("Error sharing content:", error);
      }
    } else {
      console.error("Web Share API not supported");
    }
  };

  const getFontSizeClass = (length) => {
    if (length <= 10) {
      return "text-4xl"; // Large font size for short titles
    } else if (length <= 20) {
      return "text-3xl"; // Medium font size for medium-length titles
    } else if (length <= 30) {
      return "text-2xl"; // Smaller font size for longer titles
    } else {
      return "text-xl"; // Smallest font size for very long titles
    }
  };

  return (
    <div className="relative bg-[#12041f]">
      <div className="absolute hero-section w-full h-[600px] !z-[0]"></div>
      <div className="lg:pt-24 hero-section-single event-page">
        <div className="lg:hidden w-full flex items-center justify-center pt-12 relative ">
          <div className="flex justify-between w-[90%] items-center">
            <Link to="/">
              <Icon
                icon="heroicons:arrow-left"
                className="w-12 h-12 text-white"
                alt="Whatsapp logo"
              />
            </Link>
            <Link to="/">
              <Logo />
            </Link>
            <CustomSelect
              options={[
                { value: "nl", label: "🇳🇱 Nederlands" },
                { value: "en", label: "🇺🇸 English" },
              ]}
              onSelect={(value) => {
                setLanguage(value);
              }}
            />
          </div>
        </div>
        <div className="max-lg:!mt-[24px] custom-container">
          <div className="event-img text-center relative">
            <img src={eventImage} alt="Loods1" />
          </div>
          <div className="xl:flex justify-between gap-3">
            <div className="relative max-lg:pl-3 lg:max-w-[50%]">
              <div className="event-heading">
                <h1 className={`line-clamp-2 w-[95%] text-start text-[32px] max-lg:leading-[36px] lg:text-[48px] font-semibold pt-12 lg:pt-20 lg:!leading-[56px]`}>
                  {eventTitle}
                </h1>
              </div>
              <div className="event-information-box">
                <ul className="row !pt-4">
                  <li className="event date">
                    <a className="text-white flex items-start">
                      <img src={LocateImg} alt="Locatie" />
                      <span className="text-color ml-1 mt-1">
                        {eventClub}, {eventCity}
                      </span>
                    </a>
                  </li>

                  <li className="event date">
                    <a className="text-white flex items-start">
                      <img src={CalendarImg} alt="Calendar" />
                      <span className="text-color ml-1 mt-1">
                        {eventDate}, {eventTime}
                      </span>
                    </a>
                  </li>

                  <li className="event date">
                    <a className="text-white flex items-start">
                      <img src={GenreIconImg} alt="Genre" />
                      <span className="text-color ml-1 mt-1">
                        {eventGenres}
                      </span>
                    </a>
                  </li>
                  {navigator.share && (
                    <li
                      className="event date cursor-pointer"
                      onClick={shareContent}
                    >
                      <a className="text-white flex items-start">
                        <img src={ShareImg} alt="Genre" />
                        <span className="text-color ml-1 mt-1">Share</span>
                      </a>
                    </li>
                  )}
                </ul>
              </div>
              <div className="mt-12 lg:mt-36 py-[24px] border-y mr-12 border-[#302640]">
                {coupon && (
                  <div className="multipan-event-box vriendenticket">
                    <div className="events-image">
                      <img src={GuestListSinImgs} alt="guest-list-sin" />
                    </div>
                    <div className="events-content">
                      <h5 className="text-white">
                        {t("receive_code_after_enter")}
                      </h5>
                      <p className="text-light-gray event-content event-code">
                        <span className="event-code__span">
                          {/*<img className='copy-icon' src={copy} alt="" />*/}
                          <span ref={textRef} onClick={handleCopyClick}>
                            {coupon}
                          </span>
                        </span>
                      </p>
                    </div>
                  </div>
                )}
                <div className="multipan-event-box vriendenticket">
                  <div className="events-image">
                    {eventCategory === "Community ticket" ? (
                      <img src={CommunityTicketImg} alt="guest-list-sin" />
                    ) : (
                      <img src={GuestListSinImg} alt="guest-list-sin" />
                    )}
                  </div>
                  <div className="events-content">
                    <h5 className="text-white">{eventCategory}</h5>
                    <p className="text-light-gray event-content">
                      {description}
                    </p>
                  </div>
                </div>

                <div className="multipan-event-box">
                  <div className="events-image">
                    <img src={UserSinImg} alt="user-sin" />
                  </div>
                  <div className="events-content">
                    <h5 className="text-white">
                      {t("minimum_age")} {eventAge}+
                    </h5>
                  </div>
                </div>

                <div className="multipan-event-box">
                  <div className="events-image">
                    <img src={WarningImg} alt="warning" />
                  </div>
                  <div className="events-content">
                    <h5 className="text-white">
                      {t("refund_and_resell_not_possible")}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <section className="event-form-sec w-full lg:max-w-[50%] lg:w-[700px] relative">
              <div>
                <div className="event-information-form " ref={refForm}>
                  <h3 className="text-white form-heading">
                    {t("your_details")}
                  </h3>
                  <form>
                    <div className="first-name event-sec-forms">
                      <div className="col-md-6">
                        <Input
                          type="text"
                          placeholder={t("first_name")}
                          name="Firstname"
                          value={eFormData.Firstname}
                          className={
                            formErrors.Firstname ? "sin error-field" : "sin"
                          }
                          onInput={onChange}
                          // autoComplete='off'
                        />
                      </div>
                      <div className="col-md-6">
                        <Input
                          type="text"
                          placeholder={t("last_name")}
                          name="Lastname"
                          value={eFormData.Lastname}
                          className={
                            formErrors.Lastname ? "sin error-field" : "sin"
                          }
                          // autoComplete='off'
                          onInput={onChange}
                        />
                      </div>
                    </div>
                    <div className="email event-sec-form">
                      <Input
                        type="email"
                        name="Email"
                        id="email"
                        value={eFormData.Email}
                        placeholder={t("email")}
                        className={formErrors.Email ? "sin error-field" : "sin"}
                        // autoComplete='off'
                        onInput={onChange}
                      />
                    </div>
                    <div
                      className={
                        formErrors.dob
                          ? "date event-sec-form error-field"
                          : "date event-sec-form"
                      }
                    >
                      <Input
                        type="text"
                        maxLength="10"
                        id="date"
                        name="dob"
                        value={eFormData.dob}
                        inputMode="numeric"
                        className={formErrors.dob ? "sin error-field" : "sin"}
                        placeholder={dobPlaceHolder}
                        onFocus={focusDob}
                        onBlur={blurDob}
                        onChange={changeDob}
                        autoComplete="off"
                      />
                      {/* <Flatpickr {...flatpickrProps} /> */}
                    </div>

                    <div className="geslacht event-sec-form sin">
                      <select
                        id="geslacht"
                        className={
                          formErrors.gender
                            ? "cx-ui-select error-field"
                            : "cx-ui-select"
                        }
                        name="gender"
                        size="1"
                        data-filter="false"
                        data-placeholder=""
                        onChange={onChange}
                      >
                        <option value="gender" disabled selected>
                          {t("gender")}
                        </option>
                        <option value="Man">{t("male")}</option>
                        <option value="Vrouw">{t("female")}</option>
                        <option value="Onzijdig">{t("other")}</option>
                      </select>
                    </div>

                    <div
                      className={
                        formErrors.agreement
                          ? "checkbox error-field"
                          : "checkbox"
                      }
                    >
                      <div className="check">
                        <Input
                          type="checkbox"
                          name="agreement"
                          id="agreement"
                          checked={eFormData.agreement}
                          onChange={(e) => {
                            onChange(e);
                            setEFormData({
                              ...eFormData,
                              agreement: e.target.checked,
                            });
                          }}
                        />
                      </div>
                      <div className="texts">
                        <label className="text-white" htmlFor="agreement">
                          {t("i_agree_to_the")}{" "}
                          <a
                            href="https://beta.22night.com/Terms_PDF/Algemene_Voorwaarden_22Night_bv.pdf"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {t("terms_and_conditions")}.
                          </a>{" "}
                        </label>
                      </div>
                    </div>

                    <div className="event-price-box destop">
                      <div className={eventPriceClass}>
                        <div
                          className={`price-box text-white ${
                            soldOutStatus === true && "w-full text-center"
                          }`}
                        >
                          <h3>
                            {soldOutStatus === true
                              ? "This event is sold out"
                              : `€${eventOurPrice}`}
                          </h3>
                          <h6>{soldOutStatus === true ? "" : priceText}</h6>
                          {/* <p>{soldOutStatus === true ? '' : eventPriceLabel}</p> */}
                        </div>

                        {soldOutStatus === false && (
                          <div className="price-button">
                            <button
                              className="button openPopupButtons"
                              type="button"
                              // onClick={createEvent}
                              onClick={handleSubmit}
                              disabled={
                                soldOutStatus === true || btnDisabled === true
                                  ? "disabled"
                                  : ""
                              }
                            >
                              {/* Koop vriendenticket */}
                              {btnText}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div id="popups" className="popups">
                <div className="popup-contents">
                  <img
                    src="assets/image svg/Illustration.svg"
                    className="Illustration"
                  />
                  <p className="Hier">{t("here_is_your_community_ticket")}</p>
                  <p className="Je">
                    Je ontvangt een e-mail van de organisatie zodra de betaling
                    is afgerond.
                  </p>
                  <a href="index.html" className="Terug">
                    {t("back_to_home")}
                  </a>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className="event-price-box mobile ">
          <div className={eventPriceClass}>
            <div className="price-box text-white">
              <h3>
                {soldOutStatus === true
                  ? "This event is sold out"
                  : `€${eventOurPrice}`}
              </h3>
              <h6>{soldOutStatus === true ? "" : priceText}</h6>
              {/* <p>{soldOutStatus === true ? '' : eventPriceLabel}</p> */}
            </div>

            <div className="price-button">
              <button
                className="button openPopupButtons"
                type="button"
                onClick={handleSubmit}
                disabled={
                  soldOutStatus === true || btnDisabled === true
                    ? "disabled"
                    : ""
                }
              >
                {btnText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleEvent;
