import { InfoSvg } from '../utils/svg';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/nl';


// import utc from 'dayjs/plugin/utc';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
dayjs.extend(utc);
dayjs.extend(timezone);

const EventCard = ({ eventCardData, singleEvent,currentCity }) => {
  const navigate = useNavigate();
  const eventClassName = `event-content text-white ${
    !singleEvent ? 'doubles' : null
  }`;
  const newTz = 'Etc/GMT-3';
  let eventName = eventCardData.EvenName;
      eventName = eventName.charAt(0) + eventName.slice(1);
  const eventTime = dayjs
    // .utc(eventCardData.EventDate)

    .unix(eventCardData.EventDate)
    .utc()
    // .tz(newTz)
    .locale('nl')
    .format('HH:mm');

  const cityName = eventCardData.CityName;
  const clubName = eventCardData.TheClub.charAt(0).toUpperCase() + eventCardData.TheClub.slice(1).toLowerCase();
  const age = eventCardData.MinimalAge;
  const category = eventCardData.EventCategorie;

  const handleItemClick = () => {
    // if (eventCardData.OutofStock['sold-out'] !== true && eventCardData.OutofStock['sold-out'] !== "true") {
      navigate(`/single-event?eventId=${eventCardData.EventId}`, {
        state: eventCardData.EventId,
      });
    // }
    // return null;
  };

  const {t} = useTranslation();

  return (
    <div className={`${eventClassName} hover:scale-[102%] transition-all`} onClick={handleItemClick}>
      <a >
        <h5 className="Charlee">
          {eventName}
          {/* {eventTime} */}
        </h5>
        <h6 className="text-light-gray">
          {clubName}, {cityName}
        </h6>

        {(eventCardData.OutofStock['sold-out'] === true || eventCardData.OutofStock['sold-out'] === "true") ? (
          <div className="Sould Out">
            <span>Sold out</span>
          </div>
        ) : (
          <div className="tooltipss">
            <span>{eventTime}</span>
            <span>{age}+</span>
            <div className="tool">
              <span className="gen">{t(category)}</span>{' '}
              <span className="tooltips">
                {' '}
                <InfoSvg />
                {category === "Community ticket" ? (
                <span className="tooltiptext tooltipText wa Vriendenticket">
                {t('community_ticket_guarantee')}
                </span>
                ) : (
                  <span className="tooltiptext tooltipText wa Vriendenticket">
                  {t('guestlist_entry')}
                </span>
                )}
              </span>
            </div>
          </div>
        )}
      </a>
    </div>
  );
};

export default EventCard;
