import { useEffect, useState } from "react";
import EventItem from "./EventItem";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { adjustTime } from '../helpers/helpers';
import 'dayjs/locale/nl';
dayjs.extend(utc);
dayjs.extend(timezone);




const EventsList = ({ eventsData, currentCity, isDivider,formattedCurrentDate, language }) => {

  const sortedByDate = eventsData
    .filter(event => event.CityName === currentCity)
    .filter(event => {
      const registerFrom = adjustTime(event.RegistrationFrom)
      const registerUntil = adjustTime(event.RegistrationUntil)
      // console.log(event.EvenName,'registerFRom', registerFrom, 'registerUntil', registerUntil, 'formattedCurrentDate', formattedCurrentDate)
      return formattedCurrentDate > registerFrom && formattedCurrentDate < registerUntil
    })
    .map(event => {
      const timestamp = parseInt(event.EventDate);
      const dateObject = dayjs.unix(timestamp).utc().locale(language);

      return {
        ...event,
        fulldate: {
          date: dateObject.format('D MMMM'),
          day: dateObject.format('dddd'),
        },
        timestamp
      };
    });

  const groupedByDate = sortedByDate.reduce((grouped, event) => {
    const date = event.fulldate.date;

    grouped[date] ??= {
      timestamp: event.timestamp,
      date: event.fulldate.date,
      day: event.fulldate.day,
      data: [],
    };

    grouped[date].data.push(event);

    return grouped;
  }, {});

  const groupedArray = Object.values(groupedByDate);

  groupedArray.sort((a, b) => a.timestamp - b.timestamp);

  return (
    <div className="custom-container">
      {
        groupedArray.map(eventsGroup => {
          return <EventItem key={eventsGroup.EventId} eventsGroupData={eventsGroup} currentCity={currentCity} />
        })
      }
      {isDivider && (
        <div className="card-divider"></div>
      )}
    </div>
  )
}

export default EventsList;