import React, { useState } from "react";
import WhatsappLogo from "../../assets/whatsapp-logo.svg";
import { CustomSelect } from "../home/Home";
import Logo from "../../components/Logo";
import { Link } from "react-router-dom";
import clsx from "clsx";
import AllDealsIcon from "../../assets/all-deals-icon.svg";
import SportIcon from "../../assets/sport-icon.svg";
import TransportIcon from "../../assets/transport-icon.svg";
import HorecaIcon from "../../assets/horeca-icon.svg";
import DealsOfTheWeek from "../../components/DealsOfTheWeek";
import DealOverview from "../../components/DealOverview";
import DealSearchBar from "../../components/DealSearchBar";
import { useTranslation } from "react-i18next";

export default function Lifestyle({ language, setLanguage, deals }) {
  const handleTabClick = (num) => {
    setTab(num);
  };

  const [tab, setTab] = useState(1);

  const { t } = useTranslation();

  return (
    <div className="bg-[#12041f] pb-[80px]">
      <div className="hero-section relative">
        <div className="lg:hidden w-full flex items-center justify-center pt-12">
          <div className="flex justify-between w-[90%] items-center">
            <Link to="https://linktr.ee/22nightmember" target="_blank">
              <img
                src={WhatsappLogo}
                className="w-12 h-12"
                alt="Whatsapp logo"
              />
            </Link>
            <Link to="/">
              <Logo />
            </Link>
            <CustomSelect
              options={[
                { value: "nl", label: "🇳🇱 Nederlands" },
                { value: "en", label: "🇺🇸 English" },
              ]}
              onSelect={(value) => {
                setLanguage(value);
              }}
            />
          </div>
        </div>
        <div className="pt-[40px] lg:pt-[80px] lg:pb-[40px] px-[10px] lg:px-[100px]">
          <div className="hero-card rounded-[16px] border-2 p-[10px] lg:p-[40px]">
            <div className="hero-tabs">
              <div
                className={`w-full items-center justify-center text-center lg:px-[20%] block`}
                data-id="tab2"
              >
                <h1
                  className="w-full max-lg:pb-[24px] text-[40px] lg:!text-[64px] text-white leading-[44px] lg:leading-[72px]"
                  id="heading"
                >
                  Community <br />
                  benefits
                </h1>
              </div>
            </div>

            <div className="hero-search-bar hero-card mt-[64px] rounded-[16px]">
              <DealSearchBar deals={deals} formattedCurrentDate={""} />
              <div className="lg:px-12 lg:!tabs pt-[22px] pb-[34px]">
                <div className="flex gap-[16px] lg:gap-12 w-full max-lg:!w-full max-lg:flex max-lg:justify-center">
                  {/* Alle deals */}
                  <div
                    className={clsx(
                      "rotterdam-tab hover:cursor-pointer",
                      tab === 1 ? "active" : ""
                    )}
                  >
                    <div
                      className={clsx("tab-a flex flex-col items-center", tab === 1 ? "active-a" : "")}
                      data-id="tab2"
                      onClick={() => handleTabClick(1)}
                    >
                      <img
                        src={AllDealsIcon}
                        alt="roterdam"
                        className="h-12 w-12"
                      />
                      <h5 className="text-white">{t("all_deals")}</h5>
                    </div>
                  </div>
                  {/* Sport */}
                  <div
                    className={clsx(
                      "rotterdam-tab hover:cursor-pointer",
                      tab === 2 ? "active" : ""
                    )}
                  >
                    <div
                      className={clsx("tab-a flex flex-col items-center", tab === 2 ? "active-a" : "")}
                      data-id="tab2"
                      onClick={() => handleTabClick(2)}
                    >
                      <img
                        src={SportIcon}
                        alt="roterdam"
                        className="h-12 w-12"
                      />
                      <h5 className="text-white">{t("sports")}</h5>
                    </div>
                  </div>
                  {/* Horeca */}
                  <div
                    className={clsx(
                      "rotterdam-tab hover:cursor-pointer",
                      tab === 3 ? "active" : ""
                    )}
                  >
                    <div
                      className={clsx("tab-a flex flex-col items-center", tab === 3 ? "active-a" : "")}
                      data-id="tab2"
                      onClick={() => handleTabClick(3)}
                    >
                      <img
                        src={HorecaIcon}
                        alt="roterdam"
                        className="h-12 w-12"
                      />
                      <h5 className="text-white">{t("catering")}</h5>
                    </div>
                  </div>
                  {/* Vervoer */}
                  <div
                    className={clsx(
                      "amsterdam-tab hover:cursor-pointer",
                      tab === 4 ? "active" : ""
                    )}
                  >
                    <div
                      className={clsx("tab-a flex flex-col items-center", tab === 4 ? "active-a" : "")}
                      data-id="tab1"
                      onClick={() => handleTabClick(4)}
                    >
                      <img
                        src={TransportIcon}
                        alt="amsterdam"
                        className="h-12 w-12"
                      />
                      <h5 className="text-white">{t("transport")}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="custom-container pb-[80px]">
        {/* Alle deals */}
        {tab === 1 && (
          <>
            <DealsOfTheWeek
              dealsOfTheWeek={deals.filter(
                (deal) => deal.isDealOfTheWeek === "true"
              )}
            />
            {deals.filter((deal) => deal.category === "sport").length > 0 && (
              <DealOverview
                deals={deals.filter((deal) => deal.category === "sport")}
                title={t("sport_deals")}
                isCarousel={true}
              />
            )}
            <DealOverview
              deals={deals.filter((deal) => deal.category === "Horeca")}
              title={t("catering_deals")}
              isCarousel={true}
            />
            <DealOverview
              deals={deals.filter((deal) => deal.category === "Vervoer")}
              title={t("transport_deals")}
              isCarousel={true}
            />
          </>
        )}

        {/* Sport */}
        {tab === 2 && (
          <DealOverview
            deals={deals.filter((deal) => deal.category === "sport")}
            title={t("sport_deals")}
            isCarousel={false}
          />
        )}

        {/* Horeca */}
        {tab === 3 && (
          <DealOverview
            deals={deals.filter((deal) => deal.category === "Horeca")}
            title={t("catering_deals")}
            isCarousel={false}
          />
        )}

        {/* Vervoer */}
        {tab === 4 && (
          <DealOverview
            deals={deals.filter((deal) => deal.category === "Vervoer")}
            title={t("transport_deals")}
            isCarousel={false}
          />
        )}
      </section>
    </div>
  );
}
