import EventCard from "./EventCard";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import "dayjs/locale/nl";
import { useTranslation } from "react-i18next";

dayjs.extend(utc);
dayjs.extend(timezone);

const EventItem = ({ eventsGroupData, currentCity }) => {
  const { t } = useTranslation();
  const today = dayjs.utc().locale("nl").format("D MMMM");
  const dayName =
    eventsGroupData.date === today ? t("today") : eventsGroupData.day;
  const date = eventsGroupData.date;
  const events = eventsGroupData.data;

  return (
    <div className="tab">
      <div className="tab-header">
        <div className="row justify-content-between text-white">
          <div className="days">
            <h3 className="donderdag">{dayName}</h3>
          </div>
          <div className="date">
            <h5 className="date pr-8">{date}</h5>
          </div>
        </div>
      </div>
      <div className="tab-body">
        <div className="event-box">
          <div className="row">
            {events.map((event) => (
              <EventCard
                key={event.EventId}
                eventCardData={event}
                singleEvent={false}
                currentCity={currentCity}
              />
            ))}
            {events.length === 1 && (
              <div className="max-lg:h-[110px] text-center event-content text-white doubles flex flex-col items-center justify-center">
                <h2 className="text-[18px] text-white font-[600] leading-[24px] tracking-normal">
                  {t("event_not_on_the_list")}
                </h2>
                <p className="mt-1 text-[14px] lg:text-[16px] font-normal leading-[22px] tracking-normal">
                  {t("let_us_know")}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventItem;
