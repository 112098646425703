import { useEffect, useState } from "react";
import clsx from "clsx";
// import axios from 'axios';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";
import "dayjs/locale/nl";
import "./Home.css";

import AmsterdamIcon from "../../assets/image svg/Amsterdam.svg";
import RotterdamIcon from "../../assets/image svg/Rotterdam.svg";
import BarcelonaIcon from "../../assets/image svg/Barcelona.svg";
import LondonIcon from "../../assets/image svg/London.svg";
// import Cards from '../../components/Cards';
import DiscoverEvent from "../../components/DiscoverEvent";
import Carousel from "../../components/Carousel";
import SearchBar from "../../components/SearchBar";
import EventsList from "../../components/EventsList";
import Logo from "../../components/Logo";
import { adjustTime } from "../../helpers/helpers";
import NoEvents from "../../components/NoEvents";
import EventItemPreloader from "../../components/EventItemPreloader";
// import eventItems from '../../utils/mockupData.json';
import React from "react";
import { useTranslation } from "react-i18next";
import WhatsappLogo from "../../assets/whatsapp-logo.svg";
import { Link } from "react-router-dom";
import Icon from "../../components/Icon";

export const CustomSelect = ({ options, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0] || "");

  const handleToggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    onSelect(option); // Propagate the selected option to the parent component
    setIsOpen(false); // Close the dropdown
  };

  return (
    <div className="">
      <button
        className="flex justify-between items-center w-full"
        onClick={handleToggleDropdown}
      >
        {/* {selectedOption ? selectedOption.label : "Select an option"} */}
        <Icon icon="heroicons:globe-alt" className="h-12 w-12 text-white" />
      </button>
      {isOpen && (
        <ul className="rounded-[10px] absolute right-[50px] w-64 list-none m-0 p-0 bg-[#130826] z-10">
          {options.map((option) => (
            <li
              key={option.value}
              onClick={() => handleOptionClick(option.value)}
              className="px-4 py-2 text-white cursor-pointer"
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

dayjs.extend(utc);
dayjs.extend(timezone);

const Home = ({
  token,
  eventData,
  genres,
  eventsLoading,
  setEventsLoading,
  language,
  setLanguage,
}) => {
  const [tab, setTab] = useState(
    Number(localStorage.getItem("tabNumber")) || 1
  );
  const [onlineEvents, setOnlineEvents] = useState([]);
  const [sortedData, setSortedData] = useState([...eventData]);
  const [tabLoading, setTabLoading] = useState(false);
  const [sortState, setSortState] = useState({
    datum: "Alle Datums",
    categorie: "Alle events",
    genre: [...genres],
    leeftijd: "Alle leeftijden",
    city:
      (Number(localStorage.getItem("tabNumber")) || 0) === 2
        ? "Rotterdam"
        : "Amsterdam",
  });

  const [currentCity, setCurrentCity] = useState(
    (Number(localStorage.getItem("tabNumber")) || 0) === 2
      ? "Rotterdam"
      : "Amsterdam"
  );

  const [currentNetherlandsTime, setCurrentNetherlandsTime] = useState(() => {
    const netherlandsTime = new Date().toLocaleString("en-US", {
      timeZone: "Europe/Amsterdam",
    });
    return netherlandsTime;
  });
  const formattedCurrentDate = dayjs(currentNetherlandsTime).format(
    "YYYY-MM-DD HH:mm:ss"
  );

  const updateLocalStorage = (keyName, items) => {
    localStorage.setItem(keyName, JSON.stringify(items));
  };

  const updateSortState = (newState) => {
    setSortState(newState);
  };

  useEffect(() => {
    console.log("SORT STATE", sortState);
  }, [sortState]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentNetherlandsTime(() => {
        const netherlandsTime = new Date().toLocaleString("en-US", {
          timeZone: "Europe/Amsterdam",
        });
        return netherlandsTime;
      }); // Update current date and time
    }, 1000); // Update every second (adjust as needed)

    return () => {
      clearInterval(timer); // Clean up the timer when the component unmounts
    };
  }, []);

  useEffect(() => {
    updateLocalStorage("allEvents", eventData);
    // setOnlineEvents(eventData.filter(event => event.OnlineSoon === "Yes" && event.CityName === currentCity));
  }, [eventData]);

  useEffect(() => {
    sortAll();
    if (eventData.length > 0) {
      setEventsLoading(false);
    }
  }, [eventData, sortState, tab]);

  const sortAll = () => {
    const sortedByDates = sortByDates(eventData);
    const sortedByCategory = sortByCategory(sortedByDates);
    const sortedByGenres = sortByGenres(sortedByCategory);
    const sortedByAge = sortByAge(sortedByGenres);
    const sortedByCity = sortByCity(sortedByAge);
    setSortedData(sortedByCity);
    setOnlineEvents(
      sortedByCity.filter(
        (event) =>
          formattedCurrentDate < adjustTime(event.RegistrationFrom) &&
          event.CityName === currentCity
      )
    );
  };

  const sortByDates = (arr) => {
    let sortedByDates = [];

    const sortStatus = sortState["datum"].toLocaleLowerCase();
    const today = dayjs().utc().locale("nl");
    const tomorrow = today.add(1, "day");
    const startOfWeek = today.startOf("week");
    const endOfWeek = today.endOf("week");
    const startOfNextWeek = today.add(1, "week").startOf("week");
    const endOfNextWeek = today.add(1, "week").endOf("week");
    const startOfMonth = today.startOf("month");
    const endOfMonth = today.endOf("month");

    console.log("SORT STATE", sortStatus);

    switch (sortStatus) {
      case "vandaag": {
        sortedByDates = arr.filter((event) => {
          const eventDate = dayjs.unix(event.EventDate).utc();
          return (
            eventDate.isSame(today, "day") &&
            eventDate.isSame(today, "month") &&
            eventDate.isSame(today, "year")
          );
        });
        break;
      }
      case "today": {
        sortedByDates = arr.filter((event) => {
          const eventDate = dayjs.unix(event.EventDate).utc();
          return (
            eventDate.isSame(today, "day") &&
            eventDate.isSame(today, "month") &&
            eventDate.isSame(today, "year")
          );
        });
        break;
      }
      case "morgen": {
        console.log("komt hier");
        sortedByDates = arr.filter((event) => {
          const eventDate = dayjs.unix(event.EventDate).utc();
          return (
            eventDate.isSame(tomorrow, "day") &&
            eventDate.isSame(tomorrow, "month") &&
            eventDate.isSame(tomorrow, "year")
          );
        });
        break;
      }
      case "tomorrow": {
        sortedByDates = arr.filter((event) => {
          const eventDate = dayjs.unix(event.EventDate).utc();
          return (
            eventDate.isSame(tomorrow, "day") &&
            eventDate.isSame(tomorrow, "month") &&
            eventDate.isSame(tomorrow, "year")
          );
        });
        break;
      }
      case "deze week": {
        sortedByDates = arr.filter((event) => {
          const eventDate = dayjs.unix(event.EventDate).utc();
          return (
            (eventDate.isAfter(startOfWeek) && eventDate.isBefore(endOfWeek)) ||
            eventDate.isSame(startOfWeek) ||
            eventDate.isSame(endOfWeek)
          );
        });
        break;
      }
      case "this week": {
        sortedByDates = arr.filter((event) => {
          const eventDate = dayjs.unix(event.EventDate).utc();
          return (
            (eventDate.isAfter(startOfWeek) && eventDate.isBefore(endOfWeek)) ||
            eventDate.isSame(startOfWeek) ||
            eventDate.isSame(endOfWeek)
          );
        });
        break;
      }
      case "volgende week": {
        sortedByDates = arr.filter((event) => {
          const eventDate = dayjs.unix(event.EventDate).utc();
          return (
            (eventDate.isAfter(startOfNextWeek) &&
              eventDate.isBefore(endOfNextWeek)) ||
            eventDate.isSame(startOfNextWeek) ||
            eventDate.isSame(endOfNextWeek)
          );
        });
        break;
      }
      case "next week": {
        sortedByDates = arr.filter((event) => {
          const eventDate = dayjs.unix(event.EventDate).utc();
          return (
            (eventDate.isAfter(startOfNextWeek) &&
              eventDate.isBefore(endOfNextWeek)) ||
            eventDate.isSame(startOfNextWeek) ||
            eventDate.isSame(endOfNextWeek)
          );
        });
        break;
      }
      case "deze maand": {
        sortedByDates = arr.filter((event) => {
          const eventDate = dayjs.unix(event.EventDate).utc();
          return (
            (eventDate.isAfter(startOfMonth) &&
              eventDate.isBefore(endOfMonth)) ||
            eventDate.isSame(startOfMonth) ||
            eventDate.isSame(endOfMonth)
          );
        });
        break;
      }
      case "this month": {
        sortedByDates = arr.filter((event) => {
          const eventDate = dayjs.unix(event.EventDate).utc();
          return (
            (eventDate.isAfter(startOfMonth) &&
              eventDate.isBefore(endOfMonth)) ||
            eventDate.isSame(startOfMonth) ||
            eventDate.isSame(endOfMonth)
          );
        });
        break;
      }
      default:
        sortedByDates = arr;
        break;
    }
    return sortedByDates;
  };

  const sortByCategory = (arr) => {
    let sortedByCategory = [];
    const sortStatus = sortState["categorie"].toLowerCase();
    switch (sortStatus) {
      case "dag event":
        sortedByCategory = arr.filter(
          (event) => event.timeofday.toLowerCase() === "dag event"
        );
        break;
      case "nacht event":
        sortedByCategory = arr.filter(
          (event) => event.timeofday.toLowerCase() === "nacht event"
        );
        break;
      case "festival":
        sortedByCategory = arr.filter(
          (event) => event.timeofday.toLowerCase() === "festival"
        );
        break;
      default:
        sortedByCategory = arr;
        break;
    }

    return sortedByCategory;
  };

  const sortByGenres = (arr) => {
    let sortedByGenres = [];
    const sortStatus = sortState["genre"]?.map((genre) => genre.toLowerCase()); // array

    if (!sortStatus || sortStatus.length === 0) {
      return [];
    } else if (sortStatus.length === 0) {
      return [];
    }

    sortedByGenres = arr.filter((event) => {
      const eventGenres = event.GenreList.split(",").map((genre) =>
        genre.trim().toLowerCase()
      );
      return eventGenres.some((selectedGenre) =>
        sortStatus.includes(selectedGenre.toLowerCase())
      );
    });

    return sortedByGenres;
  };

  const sortByAge = (arr) => {
    let sortedByAge = [];
    const sortStatus = sortState["leeftijd"];

    switch (sortStatus) {
      case "18+":
        sortedByAge = arr;
        break;
      case "21+":
        sortedByAge = arr.filter((event) => +event.MinimalAge >= 21);
        break;
      case "23+":
        sortedByAge = arr.filter((event) => +event.MinimalAge >= 23);
        break;
      default:
        sortedByAge = arr;
        break;
    }
    return sortedByAge;
  };

  const sortByCity = (arr) => {
    let sortedByCity = [];
    const sortStatus = sortState["city"];

    switch (sortStatus) {
      case "Amsterdam":
        sortedByCity = arr.filter((event) =>
          event.CityName.includes("Amsterdam")
        );
        break;

      case "Rotterdam":
        sortedByCity = arr.filter((event) =>
          event.CityName.includes("Rotterdam")
        );
        break;
      default:
        sortedByCity = arr.filter(
          (event) =>
            event.CityName.includes("Amsterdam") ||
            event.CityName.includes("Rotterdam")
        );

        break;
    }
    return sortedByCity;
  };

  const handleTabClick = (tabNumber) => {
    localStorage.setItem("tabNumber", String(tabNumber));
    setTabLoading(true);
    setSortState((prevState) => ({
      datum: "Alle Datums",
      categorie: "Alle events",
      genre: [...genres],
      leeftijd: "Alle leeftijden",
      city: tabNumber === 1 ? "Amsterdam" : "Rotterdam",
    }));
    setCurrentCity(tabNumber === 1 ? "Amsterdam" : "Rotterdam");
    setTab(tabNumber);
    sortAll();
    // will remove preloader when data is filtered

    setTimeout(() => {
      setTabLoading(false);
      //   setEventsLoading(false);
    }, 200);
  };

  const { t } = useTranslation();

  const showOnlineEventsSection = onlineEvents.length > 0;

  return (
    <div className="bg-[#12041f]">
      <div className="hero-section relative">
        <div className="lg:hidden w-full flex items-center justify-center pt-12">
          <div className="flex justify-between w-[90%] items-center">
            <Link to="https://linktr.ee/22nightmember" target="_blank">
              <img
                src={WhatsappLogo}
                className="w-12 h-12"
                alt="Whatsapp logo"
              />
            </Link>
            <Link to="/">
              <Logo />
            </Link>
            <CustomSelect
              options={[
                { value: "nl", label: "🇳🇱 Nederlands" },
                { value: "en", label: "🇺🇸 English" },
              ]}
              onSelect={(value) => {
                setLanguage(value);
              }}
            />
          </div>
        </div>
        <div className="pt-[40px] lg:pt-[80px] lg:pb-[40px] px-[10px] lg:px-[100px]">
          <div className="hero-card rounded-[16px] border-2 p-[10px] lg:p-[40px]">
            <div className="hero-tabs">
              <div
                className={`flex w-full items-center justify-center text-center lg:px-[12%] ${
                  tab === 1 ? "block  tab-active" : "hidden"
                }`}
                data-id="tab1"
              >
                <h1
                  className="2xl:px-24 w-full max-lg:pb-[24px] text-[40px] lg:!text-[64px] text-white leading-[44px] lg:leading-[72px]"
                  id="heading"
                >
                  {t("guestlist")} <br />
                  Amsterdam
                </h1>
              </div>

              <div
                className={`flex w-full items-center justify-center text-center lg:px-[20%] ${
                  tab === 2 ? "block  tab-active" : "hidden"
                }`}
                data-id="tab2"
              >
                <h1
                  className="w-full max-lg:pb-[24px] text-[40px] lg:!text-[64px] text-white leading-[44px] lg:leading-[72px]"
                  id="heading"
                >
                  {t("guestlist")} <br />
                  Rotterdam
                </h1>
              </div>
            </div>

            <div className="hero-search-bar hero-card mt-[64px] rounded-[16px]">
              <SearchBar
                eventData={eventData}
                formattedCurrentDate={formattedCurrentDate}
              />
              <div className="max-lg:!pl-0 px-12 tabs">
                <div className="flex gap-[16px] lg:gap-12">
                  <div
                    className={clsx(
                      "amsterdam-tab hover:cursor-pointer",
                      tab === 1 ? "active" : ""
                    )}
                  >
                    <div
                      className={clsx("tab-a", tab === 1 ? "active-a" : "")}
                      data-id="tab1"
                      onClick={() => handleTabClick(1)}
                    >
                      <img
                        src={AmsterdamIcon}
                        alt="amsterdam"
                        className="amsterdam"
                      />
                      <h5 className="text-white">Amsterdam</h5>
                    </div>
                  </div>

                  <div
                    className={clsx(
                      "rotterdam-tab hover:cursor-pointer",
                      tab === 2 ? "active" : ""
                    )}
                  >
                    <div
                      className={clsx("tab-a", tab === 2 ? "active-a" : "")}
                      data-id="tab2"
                      onClick={() => handleTabClick(2)}
                    >
                      <img
                        src={RotterdamIcon}
                        alt="roterdam"
                        className="Rotterdam"
                      />
                      <h5 className="text-white">Rotterdam</h5>
                    </div>
                  </div>
                  {/* <div
                    className={clsx(
                      "rotterdam-tab hover:cursor-pointer",
                      tab === 2 ? "active" : ""
                    )}
                  >
                    <div
                      className={clsx(
                        "tab-a hover:cursor-not-allowed",
                        tab === 3 ? "active-a" : ""
                      )}
                      data-id="tab2"
                      // onClick={() => handleTabClick(2)}
                    >
                      <img
                        src={BarcelonaIcon}
                        alt="barcelona"
                        className="Barcelona !text-gray-400 h-[29px] w-[30px]"
                      />
                      <h5 className="text-gray-400 p-0">Barcalona</h5>
                    </div>
                  </div>
                  <div
                    className={clsx(
                      "rotterdam-tab hover:cursor-pointer",
                      tab === 2 ? "active" : ""
                    )}
                  >
                    <div
                      className={clsx(
                        "tab-a hover:cursor-not-allowed",
                        tab === 3 ? "active-a" : ""
                      )}
                      data-id="tab2"
                      // onClick={() => handleTabClick(2)}
                    >
                      <img
                        src={LondonIcon}
                        alt="london"
                        className="Barcelona !text-gray-400 h-[29px] w-[30px]"
                      />
                      <h5 className="text-gray-400 p-0">London</h5>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Ontdek envenment */}
      <section>
        <DiscoverEvent
          sortState={sortState}
          updateSortState={updateSortState}
          token={token}
          city={currentCity}
          genres={genres}
        />
      </section>

      {/*  */}

      <section className="zoek-evenementen !pb-[80px]">
        {eventsLoading || tabLoading ? (
          <div className="custom-container">
            <EventItemPreloader />

            {/* {isDivider && ( */}
            <div className="card-divider"></div>
            {/* )} */}
          </div>
        ) : sortedData.length > 0 ? (
          <EventsList
            eventsData={sortedData}
            currentCity={currentCity}
            isDivider={showOnlineEventsSection}
            formattedCurrentDate={formattedCurrentDate}
            language={language}
          />
        ) : (
          <NoEvents />
        )}

        {/* <NoEvents /> */}
      </section>

      {showOnlineEventsSection && (
        <>
          {/* <div className="card-divider"></div> */}
          <section>
            <div className="custom-container">
              <h2 className="binnenkort">{t("coming_soon")}</h2>
              <p className="text-white text-p">{t("want_to_be_reminded")}</p>
            </div>
          </section>
        </>
      )}
      {showOnlineEventsSection && (
        <section className="pb-[80px]">
          <Carousel
            onlineEvents={onlineEvents}
            city={currentCity}
            formattedCurrentDate={formattedCurrentDate}
          />
        </section>
      )}
    </div>
  );
};
export default Home;
