import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Scrollbar, A11y } from "swiper";

import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import { useNavigate } from "react-router-dom";

export default function DealOverview({ deals, isCarousel, title }) {
  const navigate = useNavigate();

  return (
    <section className="max-lg:px-[10px]">
      <h1 className="lg:pt-[40px] pt-[10px] pb-[20px] text-start text-[22px] leading-[26px]">
        {title}
      </h1>
      {isCarousel && (
        <Swiper
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
          }}
          breakpoints={{
            // when window width is >= 640px
            340: {
              // width: 640,
              slidesPerView: 1.1,
            },
            // when window width is >= 768px
            768: {
              width: 768,
              slidesPerView: 2.6,
            },
          }}
          modules={[Navigation, Scrollbar, A11y]}
          // slidesPerView={'2.5'}
          navigation={deals.length > 2 ? true : false}
          pagination={{
            clickable: true,
          }}
          scrollbar={{ draggable: true }}
          isDuplicate={deals.length > 2 ? true : false}
          // onSlideChange={(swiper) => disableSwiperBtns(swiper.activeIndex)}
          className="custom-arrow"
          // spaceBetween={10}
        >
          {deals.map((item, index) => (
            <SwiperSlide key={index} className="!w-[400px] !max-w-[400px] h-fit">
              <div
                onClick={() => {
                  navigate(`/deal?deal_id=${item.id}`);
                }}
                className="slider-item hover:cursor-pointer h-[220px] max-h-[220px] !max-w-[400px] !w-[400px] relative transition-all hover:scale-[102%] duration-500"
              >
                <img
                  src={item.image_url}
                  className="w-full h-[60%] rounded-t-2xl"
                  alt="Placeholder"
                />
                <div className="flex flex-col gap-y-1 p-[16px] z-100">
                  <h2 className="text-[18px] leading-[22px] text-white font-semibold">
                    {item.title}
                  </h2>
                  <p className="line-clamp-2 text-[14px] leading-[16px] text-neutral-200 font-medium">
                    {item.teaser}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      {!isCarousel && (
        <div className="grid grid-cols-1 lg:grid-cols-3 w-full max-lg:!gap-x-[0px] gap-[20px] pb-[80px]">
          {deals.map((item, index) => (
            <div key={index} className="swiper w-full">
              <div
                onClick={() => {
                  navigate(`/deal?deal_id=${item.id}`);
                }}
                className="slider-item hover:cursor-pointer h-[220px] max-h-[220px] hover:scale-[102%] transition-all w-full"
              >
                <img
                  src={item.image_url}
                  className="w-full h-[60%] object-cover"
                  alt="Placeholder"
                />
                <div className="flex flex-col gap-y-1 p-[16px] z-100">
                  <h2 className="text-[18px] leading-[22px] text-white font-semibold">
                    {item.title}
                  </h2>
                  <p className="text-[14px] leading-[16px] text-white font-medium">
                    {item.teaser}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </section>
  );
}
