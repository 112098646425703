import Logo from "./Logo";
import { Link } from "react-router-dom";
import Icon from "./Icon";
import WhatsappLogo from "../assets/whatsapp-logo.svg";
import DjSet from "../assets/dj-set.svg";
import FeedbackIcon from "../assets/feedback-icon.svg";
import ShoppingBag from "../assets/shopping-bag.svg";
import { useEffect } from "react";

export const actualMenuItems = [
  {
    isHeadr: true,
    title: "Learn",
  },
  {
    title: "Courses",
    icon: "heroicons-outline:collection",
    isHide: true,
    link: "/courses",
  },
  {
    title: "Practice Topics",
    icon: "heroicons-outline:puzzle-piece",
    isHide: false,
    link: "/practice",
  },
  {
    title: "Revise",
    icon: "heroicons-outline:arrow-uturn-left",
    isHide: true,
    link: "/revise",
    badge: "Soon",
  },
  {
    title: "My Projects",
    icon: "heroicons-outline:code-bracket",
    isHide: true,
    link: "/projects",
    badge: "Soon",
  },
  {
    isHeadr: true,
    title: "Resources",
  },
  {
    title: "Cheatsheets",
    isHide: true,
    link: "/cheatsheet",
    icon: "heroicons-outline:document-magnifying-glass",
  },
  {
    title: "Blogs",
    isHide: true,
    link: "/blog",
    icon: "heroicons-outline:book-open",
  },
];

const Sidebar = ({ language, setLanguage }) => {
  const currentSlug = window.location.href.split("/").pop();

  return (
    <div>
      <div
        className="fixed top-0 z-[999] h-screen bg-[#00000040] py-[64px] px-[24px] text-white lg:w-[300px] w-[0px] shadow-lg"
        style={{
          transition: "width 0.2s cubic-bezier(0.39, 0.575, 0.565, 1)",
        }}
      >
        <Link to="/" className="">
          <div className="ml-6">
            <Logo />
          </div>
        </Link>

        <div className="sidebar-menu h-auto mt-[32px]">
          <Link
            to="/"
            className={`mb-[16px] p-[12px] flex items-center justify-start gap-[12px] rounded-lg font-normal !text-white ${
              currentSlug === "" ? "bg-[#D0ADFB20]" : "hover:bg-[#D0ADFB20]"
            } hover:shadow-lg`}
          >
            <img src={DjSet} className="h-[20px] w-[20px]" alt="DJ Set" />
            <p className="text-[16px]">Events</p>
          </Link>
          <Link
            to="/lifestyle"
            className={`mb-[16px] p-[12px] flex items-center justify-start gap-[12px] rounded-lg font-normal !text-white ${
              currentSlug === "lifestyle"
                ? "bg-[#D0ADFB20]"
                : "hover:bg-[#D0ADFB20]"
            } hover:shadow-lg`}
          >
            <img
              src={ShoppingBag}
              className="h-[20px] w-[20px]"
              alt="Shopping bag"
            />

            <p className="text-[16px]">Lifestyle</p>
          </Link>
          <Link
            to="https://yyo3i6vjjkc.typeform.com/to/LDhqX8fB"
            target="_blank"
            // to="/"
            className=" mb-[16px] p-[12px] flex items-center justify-start gap-[12px] rounded-lg font-normal hover:bg-[#D0ADFB1A] hover:shadow-lg !text-white"
          >
            <img
              src={FeedbackIcon}
              className="h-[20px] w-[20px]"
              alt="Feedback icon"
            />
            <p className="text-[16px]">Feedback</p>
          </Link>
          <div
            // to="/"
            className=" mb-[12px] p-[12px] flex items-center justify-start gap-[12px] rounded-lg font-normal hover:bg-[#D0ADFB1A] hover:shadow-lg !text-gray-400 hover:cursor-not-allowed"
          >
            <Icon
              icon="heroicons:user-circle"
              className="h-[20px] w-[20px] text-gray-400"
            />
            <p className="text-[16px]">Log in</p>
            <div className="px-2 py-1 rounded-lg bg-indigo-800 bg-opacity-50 text-white text-xl">
              Coming soon
            </div>
          </div>
          {/* <Link
            to="/"
            className="mb-[16px] p-[12px] flex items-center justify-start gap-[12px] rounded-lg font-normal !text-white hover:bg-[#D0ADFB1A] hover:shadow-lg"
          >
            <Icon
              icon="heroicons:device-phone-mobile"
              className="h-[20px] w-[20px] text-white"
            />
            <p className="text-[16px]">Deals</p>
          </Link> */}
          <div className="pr-[48px] w-full absolute bottom-[40px]">
            <div className="mb-[16px] p-[12px] flex items-center justify-start gap-[12px !text-white rounded-lg font-normal hover:bg-[#D0ADFB1A] hover:shadow-lg hover:cursor-pointer">
              <Icon
                icon="heroicons:globe-alt"
                className="h-[20px] w-[20px] text-white"
              />
              <select
                value={language}
                onChange={(e) => {
                  setLanguage(e.target.value);
                }}
                className="w-[90%] cursor-pointer pl-[12px] text-[16px] rounded-lg font-normal !text-white bg-transparent"
              >
                <option value="nl">Nederlands</option>
                <option value="en">English</option>
              </select>
            </div>
            <Link
              to="https://linktr.ee/22nightmember"
              target="_blank"
              className="mb-[16px] p-[12px] flex items-center justify-start gap-[12px] rounded-lg font-normal !text-white hover:bg-[#D0ADFB1A] hover:shadow-lg"
            >
              <img
                src={WhatsappLogo}
                className="h-[20px] w-[20px]"
                alt="Whatsapp logo"
              />
              <p className="text-[16px]">Join community chat</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
