import { useEffect, useState, useRef } from "react";
// import axios from 'axios';
import { Input } from "@chakra-ui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import LocateImg from "../assets/image svg/Locatie.svg";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import "dayjs/locale/nl";
import { CustomSelect } from "../pages/home/Home";
import Logo from "../components/Logo";

import { signUpEventAPI } from "../utils/config";
import { useTranslation } from "react-i18next";
import Icon from "../components/Icon";
import ShareImg from "../assets/share-img.svg";
import { useCookies } from "react-cookie";



dayjs.extend(utc);

const SingleEvent = ({ dealData, setLanguage, language, token }) => {
  const { t } = useTranslation();

  const pathname = window.location.href.split("=").at(-1);

  const navigate = useNavigate();
  const [deal, setDeal] = useState([]);

  const [soldOutStatus, setSoldOutStatus] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [eFormData, setEFormData] = useState({
    Firstname: "",
    Lastname: "",
    Email: "",
    dob: "",
    gender: "",
    event: "",
    event_id: "",
    token: token,
  });
  const [eventLink, setEventLink] = useState("");
  const [dobPlaceHolder, setDobPlaceHolder] = useState(t("date_of_birth"));
  const [btnDisabled, setBtnDisabled] = useState(false);
  const refForm = useRef(null);

  const [cookie, setCookie] = useCookies(["token"]);

  //   Find the deal that is supposed to be displayed in the deal page.
  useEffect(() => {
    if (typeof pathname !== typeof 0) {
      // Find the chosen deal with find()
      const chosenDeal = dealData.find((d) => d.id === Number(pathname));

      // setCookie("token", "AMSLIJST", {
      //   path: "/",
      //   expires: new Date(Date.now() + 10 * 365 * 24 * 60 * 60 * 1000), // (10 * 60 * 60 * 1000) 10 minutes in milliseconds
      // });

      setDeal(chosenDeal);

      //   Configure sold out and ticket link properties
      const soldOutText = chosenDeal.sold_out["sold-out"];
      console.log("CHOSENDEAL", soldOutText);
      const ticketLink = chosenDeal.ticketDeal || "";
      if (soldOutText === true || soldOutText === "true") {
        setSoldOutStatus(true);
      }
      if (ticketLink !== "") {
        if (ticketLink.includes("http://localhost/")) {
          const modifiedLink = ticketLink.replace("http://localhost/", "");
          setEventLink(modifiedLink);
        } else {
          setEventLink(ticketLink);
        }
      }
      setEFormData({
        ...eFormData,
        event: chosenDeal.title,
        event_id: chosenDeal.id,
      });
    } else {
      navigate("/");
    }
  }, []);

  const onChange = (e) => {
    setEFormData({ ...eFormData, [e.target.name]: e.target.value.trim() });

    switch (e.target.name) {
      case "Firstname":
        if (formErrors.hasOwnProperty("Firstname") && formErrors.Firstname) {
          setFormErrors({
            ...formErrors,
            Firstname: !validateName(eFormData.Firstname),
          });
        }
        break;

      case "Lastname":
        if (formErrors.hasOwnProperty("Lastname") && formErrors.Lastname) {
          setFormErrors({
            ...formErrors,
            Lastname: !validateName(eFormData.Lastname),
          });
        }
        break;
      case "Email":
        if (formErrors.hasOwnProperty("Email") && formErrors.Email) {
          setFormErrors({
            ...formErrors,
            Email: !validateEmail(eFormData.Email),
          });
        }
        break;
      case "gender":
        if (formErrors.hasOwnProperty("gender") && formErrors.gender) {
          setFormErrors({
            ...formErrors,
            gender: ["Man", "Vrouw", "Onzijdig"].includes(eFormData.gender),
          });
        }
        break;
      case "agreement":
        setFormErrors({ ...formErrors, agreement: !e.target.value });
        break;
      case "dob":
        setFormErrors({ ...formErrors, dob: !validateDob(eFormData.dob) });

      default:
        break;
    }
  };

  const getThanks = (link) => {
    navigate(`/thanksDeal?link=${link}`);
  };

  const focusDob = () => {
    setDobPlaceHolder("dd-mm-jjjj");
  };

  const blurDob = () => {
    setDobPlaceHolder(t("date_of_birth"));
  };

  const changeDob = (e) => {
    const value = e.target.value;
    let formattedValue = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    let placeholder = "dd-mm-jjjj";
    let cursorPosition = formattedValue.length;
    const key = e.nativeEvent.inputType;

    const padWithZero = (number) => {
      return number.toString().padStart(2, "0");
    };

    // Format day (dd)
    if (formattedValue.length >= 2) {
      let day = parseInt(formattedValue.slice(0, 2), 10);

      if (day < 1) {
        day = 1;
      } else if (day > 31) {
        day = 31;
      }

      formattedValue = padWithZero(day) + "-" + formattedValue.slice(2);
      placeholder = formattedValue + placeholder.slice(3);
      cursorPosition = 3;
    }

    // Format month (mm)
    if (formattedValue.length >= 5) {
      let month = parseInt(formattedValue.slice(3, 5), 10);

      if (month < 1) {
        month = 1;
      } else if (month > 12) {
        month = 12;
      }

      formattedValue =
        formattedValue.slice(0, 3) +
        padWithZero(month) +
        "-" +
        formattedValue.slice(5);
      placeholder = placeholder.slice(0, 3) + "-mm-" + placeholder.slice(5);
      cursorPosition = 6;
    }

    // Format year (jjjj)
    if (formattedValue.length >= 10) {
      let year = parseInt(formattedValue.slice(6, 10), 10);

      const minYear = 1930;
      const maxYear = 2023;

      if (year < minYear) {
        year = minYear;
      } else if (year > maxYear) {
        year = maxYear;
      }

      formattedValue =
        formattedValue.slice(0, 6) + year.toString().padStart(4, "0");
    }

    if (
      key === "deleteContentBackward" &&
      (formattedValue.length === 6 || formattedValue.length === 3)
    ) {
      formattedValue =
        formattedValue.slice(0, cursorPosition - 1) +
        formattedValue.slice(cursorPosition);
      cursorPosition -= 1;
    }

    setEFormData({ ...eFormData, dob: formattedValue });
    setDobPlaceHolder(placeholder);
  };

  async function sendEventData(dataObject) {
    // console.log('eventForm Data', eFormData);
    try {
      const res = await fetch(`${signUpEventAPI}`, {
        mode: "cors",
        method: "POST",
        body: JSON.stringify(dataObject),
      });
      const response = await res.json();
      console.log("create post request--->", response);
      // if (response.status) {
      //   window.alert(response.msg);
      // }
      return response;
    } catch (err) {
      console.error("Error===>", err);
    }
  }
  const validateName = (name) => {
    return /^[A-Za-z]+(\s[A-Za-z]+)*$/.test(name);
  };

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const validateDob = (dob) => {
    if (dob.length < 10) {
      return false;
    } else {
      return true;
    }
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!validateName(eFormData.Firstname)) {
      errors.Firstname = true;
      isValid = false;
    }

    if (!validateName(eFormData.Lastname)) {
      errors.Lastname = true;
      isValid = false;
    }

    if (!validateEmail(eFormData.Email)) {
      errors.Email = true;
      isValid = false;
    }

    if (eFormData.dob.length < 10) {
      errors.dob = true;
      isValid = false;
    }

    if (!["Man", "Vrouw", "Onzijdig"].includes(eFormData.gender)) {
      errors.gender = true;
      isValid = false;
    }

    if (!eFormData.agreement) {
      errors.agreement = true;
      isValid = false;
    }

    setFormErrors(errors);

    return isValid;
  };

  const handleSubmit = async (e, isScroll = false) => {
    setBtnDisabled(true);
    e.preventDefault();

    if (isScroll) {
      refForm.current?.scrollIntoView({ behavior: "smooth" });
    }

    if (validateForm()) {
      // console.log('eFormData:', eFormData);
      const { agreement, ...dataToSend } = eFormData;
      await sendEventData(dataToSend);
      setBtnDisabled(false);
      var newTab = window.open(deal.ticket_link, "_blank");
      if (newTab) {
        newTab.opener = null; // Set opener to null for security
        newTab.location.href = deal.ticket_link; // Set the location explicitly
      } else {
        // Fallback: Open link in the current window if the new tab couldn't be opened
        window.location.href = deal.ticket_link;
      }
      // console.log('response status', response.status)
      getThanks(deal.ticket_link);
    } else {
      setBtnDisabled(false);
      console.error("Form validation Error");
    }
  };

  const eventOriginalPrice = deal.original_price;
  const eventOurPrice = deal.our_price;
  const eventPriceLabel = `${t("instead_of")} ` || "";

  const priceText = `${eventPriceLabel} €${eventOriginalPrice}`;
  const eventPriceClass =
    soldOutStatus === true
      ? "soldout row justify-content-between"
      : "row justify-content-between";

  const shareContent = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: deal.title,
          text: `Check out ${deal.title} on 22night!`,
          url: `${window.location.href}&token=AMSLIJST`,
        });
        console.log("Content shared successfully!");
      } catch (error) {
        console.error("Error sharing content:", error);
      }
    } else {
      console.error("Web Share API not supported");
    }
  };

  return (
    <div className="relative bg-[#12041f]">
      <div className="absolute hero-section w-full h-[600px] !z-[0]"></div>
      <div className="lg:pt-24 hero-section-single event-page">
        <div className="lg:hidden w-full flex items-center justify-center pt-12 relative ">
          <div className="flex justify-between w-[90%] items-center">
            <Link to="/">
              <Icon
                icon="heroicons:arrow-left"
                className="w-12 h-12 text-white"
                alt="Whatsapp logo"
              />
            </Link>
            <Link to="/">
              <Logo />
            </Link>
            <CustomSelect
              options={[
                { value: "nl", label: "🇳🇱 Nederlands" },
                { value: "en", label: "🇺🇸 English" },
              ]}
              onSelect={(value) => {
                setLanguage(value);
              }}
            />
          </div>
        </div>
        <div className="max-lg:!mt-[24px] custom-container">
          <div className="event-img text-center relative">
            <img src={deal.image_url} alt="Loods1" className="!object-fill" />
          </div>
          <div className="xl:flex justify-between gap-3">
            <div className="relative max-lg:pl-3">
              <div className="event-heading">
                <h1 className="w-[95%] text-start text-[32px] max-lg:leading-[36px] lg:text-[48px] font-semibold pt-12 lg:pt-20 lg:!leading-[56px]">
                  {deal.title}
                </h1>
              </div>
              <div className="event-information-box">
                <ul className="row !pt-4">
                  {deal.location?.split(", ").map((location) => {
                    return (
                      <li className="event date">
                        <a className="text-white flex items-start">
                          <img src={LocateImg} alt="Locatie" />
                          <span className="text-color ml-1 mt-1">
                            {location}
                          </span>
                        </a>
                      </li>
                    );
                  })}
                  {navigator.share && (
                    <li
                      className="event date cursor-pointer"
                      onClick={shareContent}
                    >
                      <a className="text-white flex items-start">
                        <img src={ShareImg} alt="Genre" />
                        <span className="text-color ml-1 mt-1">Share</span>
                      </a>
                    </li>
                  )}
                </ul>
              </div>
              <div className="mt-12 py-[24px] border-y mr-3 lg:mr-12 border-[#302640]">
                <div className="multipan-event-box vriendenticket">
                  <div className="events-content">
                    <h5 className="text-white !text-[16px] !leading-[22px]">
                      {deal.voordeel1_title}
                    </h5>
                    <p className="text-neutral-300 event-content !text-[14px] !leading-[20px]">
                      {deal.voordeel1_description}
                    </p>
                  </div>
                </div>
                <div className="multipan-event-box vriendenticket">
                  <div className="events-content">
                    <h5 className="text-white !text-[16px] !leading-[22px]">
                      {deal.voordeel2_title}
                    </h5>
                    <p className="text-neutral-300 event-content !text-[14px] !leading-[20px]">
                      {deal.voordeel2_description}
                    </p>
                  </div>
                </div>
                <div className="multipan-event-box vriendenticket">
                  <div className="events-content">
                    <h5 className="text-white !text-[16px] !leading-[22px]">
                      {deal.voordeel3_title}
                    </h5>
                    <p className="text-neutral-300 event-content !text-[14px] !leading-[20px]">
                      {deal.voordeel3_description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <section className="event-form-sec w-full lg:w-[700px] relative">
              <div>
                <div className="event-information-form " ref={refForm}>
                  <h3 className="text-white form-heading">
                    {t("your_details")}
                  </h3>
                  <form>
                    <div className="first-name event-sec-forms">
                      <div className="col-md-6">
                        <Input
                          type="text"
                          placeholder={t("first_name")}
                          name="Firstname"
                          value={eFormData.Firstname}
                          className={
                            formErrors.Firstname ? "sin error-field" : "sin"
                          }
                          onInput={onChange}
                          // autoComplete='off'
                        />
                      </div>
                      <div className="col-md-6">
                        <Input
                          type="text"
                          placeholder={t("last_name")}
                          name="Lastname"
                          value={eFormData.Lastname}
                          className={
                            formErrors.Lastname ? "sin error-field" : "sin"
                          }
                          // autoComplete='off'
                          onInput={onChange}
                        />
                      </div>
                    </div>
                    <div className="email event-sec-form">
                      <Input
                        type="email"
                        name="Email"
                        id="email"
                        value={eFormData.Email}
                        placeholder={t("email")}
                        className={formErrors.Email ? "sin error-field" : "sin"}
                        // autoComplete='off'
                        onInput={onChange}
                      />
                    </div>
                    <div
                      className={
                        formErrors.dob
                          ? "date event-sec-form error-field"
                          : "date event-sec-form"
                      }
                    >
                      <Input
                        type="text"
                        maxLength="10"
                        id="date"
                        name="dob"
                        value={eFormData.dob}
                        inputMode="numeric"
                        className={formErrors.dob ? "sin error-field" : "sin"}
                        placeholder={dobPlaceHolder}
                        onFocus={focusDob}
                        onBlur={blurDob}
                        onChange={changeDob}
                        autoComplete="off"
                      />
                      {/* <Flatpickr {...flatpickrProps} /> */}
                    </div>

                    <div className="geslacht event-sec-form sin">
                      <select
                        id="geslacht"
                        className={
                          formErrors.gender
                            ? "cx-ui-select error-field"
                            : "cx-ui-select"
                        }
                        name="gender"
                        size="1"
                        data-filter="false"
                        data-placeholder=""
                        onChange={onChange}
                      >
                        <option value="gender" disabled selected>
                          {t("gender")}
                        </option>
                        <option value="Man">{t("male")}</option>
                        <option value="Vrouw">{t("female")}</option>
                        <option value="Onzijdig">{t("other")}</option>
                      </select>
                    </div>

                    <div
                      className={
                        formErrors.agreement
                          ? "checkbox error-field"
                          : "checkbox"
                      }
                    >
                      <div className="check">
                        <Input
                          type="checkbox"
                          name="agreement"
                          id="agreement"
                          checked={eFormData.agreement}
                          onChange={(e) => {
                            onChange(e);
                            setEFormData({
                              ...eFormData,
                              agreement: e.target.checked,
                            });
                          }}
                        />
                      </div>
                      <div className="texts">
                        <label className="text-white" htmlFor="agreement">
                          {t("i_agree_to_the")}{" "}
                          <a
                            href="https://beta.22night.com/Terms_PDF/Algemene_Voorwaarden_22Night_bv.pdf"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {t("terms_and_conditions")}.
                          </a>{" "}
                        </label>
                      </div>
                    </div>

                    <div className="event-price-box destop">
                      <div className={eventPriceClass}>
                        <div className="price-box text-white">
                          <h3>
                            {soldOutStatus === true && "This deal is sold out"}
                          </h3>
                        </div>

                        {soldOutStatus === false && (
                          <div className="price-button !w-full">
                            <button
                              className="button openPopupButtons !w-full"
                              type="button"
                              // onClick={createEvent}
                              onClick={handleSubmit}
                              disabled={
                                soldOutStatus === true || btnDisabled === true
                                  ? "disabled"
                                  : ""
                              }
                            >
                              {/* Koop vriendenticket */}
                              Claim deal
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div id="popups" className="popups">
                <div className="popup-contents">
                  <img
                    src="assets/image svg/Illustration.svg"
                    className="Illustration"
                  />
                  <p className="Hier">{t("here_is_your_community_ticket")}</p>
                  <p className="Je">
                    Je ontvangt een e-mail van de organisatie zodra de betaling
                    is afgerond.
                  </p>
                  <a href="index.html" className="Terug">
                    {t("back_to_home")}
                  </a>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className="event-price-box mobile ">
          <div className={eventPriceClass}>
            <div className="price-box text-white">
              <h3>{soldOutStatus === true && "This deal is sold out"}</h3>
            </div>

            <div className="price-button !w-full ">
              <button
                className="button openPopupButtons !w-full"
                type="button"
                onClick={handleSubmit}
                disabled={
                  soldOutStatus === true || btnDisabled === true
                    ? "disabled"
                    : ""
                }
              >
                {/* Koop vriendenticket */}
                Claim deal
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleEvent;
